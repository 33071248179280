











































































import { Observer } from 'mobx-vue'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IdoApplyViewModel } from '../viewmodel/ido-apply-viewmodel'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component
export default class Footer extends Vue {
  @Prop() vm!: IdoApplyViewModel
  walletStore = walletStore
}
