var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-radius-10 algo-grey darken-1 d-flex flex-column fill-height",class:{
    'px-6 py-7': !_vm.$vuetify.breakpoint.lgAndDown,
    'px-4 py-5': _vm.$vuetify.breakpoint.lgAndDown,
  }},[_vm._l((_vm.vm.timelineSteps),function(item,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center justify-center circle-step",class:{
          'passed-step-border': _vm.vm.currentStep > index,
          'current-step': _vm.vm.currentStep === index,
          'normal-step': _vm.vm.currentStep < index,
        }},[(_vm.vm.currentStep <= index)?_c('div',[_vm._v(" "+_vm._s(index + 1)+" ")]):_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"primary","small":_vm.$vuetify.breakpoint.lgAndDown}},[_vm._v("mdi-check")])],1)]),(index < _vm.vm.timelineSteps.length - 1)?_c('div',{staticClass:"flex-fill vertical-divider",class:{
          primary: index < _vm.vm.currentStep,
        }}):_vm._e()]),_c('div',{staticClass:"ml-3 fill-width",class:{
        'step-gray-text': _vm.vm.currentStep < index,
      }},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-1"},[_c('div',{staticClass:"d-flex align-center timeline-title"},[_vm._v(" "+_vm._s(item.title)+" "),(!_vm.vm.isWhitelistEnded && index === 1)?_c('span',{staticClass:"ml-2 kyc-status-text",class:_vm.walletStore.isKycVerified ? 'primary--text' : 'error--text'},[_vm._v(_vm._s(_vm.vm.kycStateText))]):_vm._e()]),(_vm.vm.currentStep <= 1 && index === 1 && !_vm.walletStore.isKycVerified)?_c('div',{staticClass:"step-time-box d-flex align-center",class:_vm.walletStore.isKycVerified ? '' : 'error'},[_vm._v(" "+_vm._s(_vm.walletStore.isLogin ? 'Proceed with KYC' : 'Registration')+" ")]):(!!item.stepTime)?_c('div',{staticClass:"step-time-box d-flex align-center primary",class:{
            'box--disabled': index < _vm.vm.currentStep,
          }},[_vm._v(" "+_vm._s(_vm._f("datetimeStandard")(item.stepTime))+" ")]):_vm._e()]),_c('div',{staticClass:"timeline-subtitle"},[_vm._v(" "+_vm._s(item.subTitle)+" ")])])])}),_c('div',{staticClass:"flex-fill"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }